<template>
    <div class="container">
        <!-- 筛选 -->
        <div class="row_box">
            <div class="search_box">
                <div class="filter">
                    <div class="li">
                        <h6>任务名称：</h6>
                        <el-input type="text" placeholder="请输入" v-model.trim="query.keyword" maxlength="50"></el-input>
                    </div>
                    <div class="li">
                        <h6>创建时间：</h6>
                        <el-date-picker type="daterange" v-model="query.date" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="YYYY-MM-DD" />
                    </div>
                    <div class="li">
                        <h6>任务状态：</h6>
                        <el-radio-group v-model="query.taskState" @change="search">
                            <el-radio-button label="">全部</el-radio-button>
                            <el-radio-button :label="1">执行中</el-radio-button>
                            <el-radio-button :label="2">已完成</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="li btn">
                        <el-button type="info" @click="reset">重置</el-button>
                        <el-button type="primary" @click="search">搜索</el-button>
                    </div>
                </div>
                <div class="btn_box">
                    <el-button type="primary" @click="toNewTask">新建任务</el-button>
                </div>
            </div>
        </div>
        <!-- 表格 -->
        <div class="row_box">
            <div class="table_box">
                <el-table :data="tableData" stripe border style="width: 100%" :header-cell-style="{ background: '#f5f5f5', color: '#80899b' }" height="550">
                    <el-table-column prop="id" label="任务id" />
                    <el-table-column prop="userName" label="创建人" />
                    <el-table-column prop="taskName" label="任务名称" />
                    <el-table-column prop="analysis" label="分析维度" />
                    <el-table-column prop="createTime" label="创建时间" />
                    <el-table-column prop="taskFinishTime" label="完成时间" />
                    <el-table-column prop="taskState" label="状态" width="100" align="center">
                        <template #default="scope">
                            <el-tag type="info" v-if="scope.row.taskState === 0">初始化</el-tag>
                            <el-tag type="warning" v-if="scope.row.taskState === 1">执行中</el-tag>
                            <el-tag type="success" v-if="scope.row.taskState === 2">已完成</el-tag>
                            <el-tag type="danger" v-if="scope.row.taskState === 3">失败</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="操作" width="150">
                        <template #default="scope">
                            <el-link type="primary" @click="toTaskDetail(scope.row.id)">查看</el-link>&nbsp;
                            <el-link type="danger" @click="onDelete(scope.row)" v-if="scope.row.taskState !== 1">删除</el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="page_box">
                    <el-pagination layout="prev, pager, next" background :total="total" :page-size="query.pageSize" v-model:current-page="query.pageNum"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import useTableData from "./useTableData";
import useDelete from "./useDelete";

const $router = useRouter();
const $route = useRoute();

// 表格数据
const { query, total, tableData, search, reset, getTableData } = useTableData();

// 删除任务
const { onDelete } = useDelete(getTableData);

// 跳转新建任务
const toNewTask = () => {
    $router.push("/egpm/material/create?reportId=" + $route.query.reportId);
};

// 跳转任务详情
const toTaskDetail = (id) => {
    const href = $router.resolve({
        path: "/egpm/material/details",
        query: {
            reportId: $route.query.reportId,
            id: id,
        },
    }).href;
    window.open(href, "_blank");
};
</script>

<style lang="scss" scoped>
.container {
    padding: 0 40px 40px 40px;
    .row_box {
        background: #fff;
        border-radius: 14px;
        padding: 20px;
        margin-bottom: 10px;

        .tit_box {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .search_box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
            .filter {
                display: flex;
                flex-wrap: wrap;

                .li {
                    margin-right: 20px;
                    margin-bottom: 10px;

                    h6 {
                        font-weight: normal;
                        color: #818a9b;
                        padding-bottom: 6px;
                        font-size: 14px;
                    }

                    :deep(.el-input__inner) {
                        border-radius: 4px;
                    }
                    &.btn {
                        padding-top: 23px;
                    }
                }
            }
        }

        .btn_box {
            text-align: center;
            padding-top: 10px;
        }

        .table_box {
            position: relative;

            .no_data {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                padding-top: 180px;
            }
            .page_box {
                display: flex;
                justify-content: flex-end;
                padding-top: 20px;
            }
        }
    }
}
</style>
